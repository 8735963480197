// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-productos-sgl-js": () => import("./../../../src/pages/productos-sgl.js" /* webpackChunkName: "component---src-pages-productos-sgl-js" */),
  "component---src-pages-resellers-js": () => import("./../../../src/pages/resellers.js" /* webpackChunkName: "component---src-pages-resellers-js" */),
  "component---src-templates-producto-js": () => import("./../../../src/templates/producto.js" /* webpackChunkName: "component---src-templates-producto-js" */)
}

